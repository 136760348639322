import './Gallery.scss';

import React from 'react';
import PropTypes from 'prop-types';

import config from '../../../config';
import { withTranslation } from 'react-i18next';

import GalleryDescription from './GalleryDescription';
import Thumbnails from './Thumbnails';

const Gallery = ({ gallery, allGalleryThumbnails, lang, t }) => {
	return (
		<div className={'gl-Gallery_Container'}>
			<div className={'gl-TextContent_Container'}>
				<h1 className={'h2-like'}>{gallery.translation[lang].title}</h1>
				<GalleryDescription lang={lang} gallery={gallery} />
			</div>
			<div className={'gl-Gallery_Instructions'}>
				{t('galleryInstructions.instructions')}
			</div>
			<Thumbnails allThumbnails={allGalleryThumbnails} lang={lang} />
		</div>
	);
};

Gallery.propTypes = {
	gallery: PropTypes.object.isRequired,
	allGalleryThumbnails: PropTypes.array.isRequired,
	lang: PropTypes.string.isRequired,
	t: PropTypes.func.isRequired,
};

export default withTranslation(config.defaultI18nextNs)(Gallery);
