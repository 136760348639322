import React from 'react';
import PropTypes from 'prop-types';

const GalleryDescription = ({ gallery, lang }) => {
	const classNames = ['gl-GalleryDescription_Content'];

	const galleryDescriptionSrc = gallery.translation[lang].description;
	const galleryDescription = galleryDescriptionSrc
		? galleryDescriptionSrc.split('\n').map((paragraph, i) => {
				return paragraph ? (
					<span key={i}>
						{paragraph}
						<br />
					</span>
				) : (
					<br key={i} />
				);
		  })
		: null;

	return galleryDescription ? (
		<div className={'gl-GalleryDescription_Container'}>
			<div className={classNames.join(' ')}>{galleryDescription}</div>
		</div>
	) : null;
};

GalleryDescription.propTypes = {
	gallery: PropTypes.object.isRequired,
	lang: PropTypes.string.isRequired,
	t: PropTypes.func.isRequired,
};

export default GalleryDescription;
