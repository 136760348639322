import React from 'react';
import PropTypes from 'prop-types';

import { GatsbyImage } from 'gatsby-plugin-image';

const ThumbnailsItem = ({ thumbnailItem, lang, isActive, zoomIn }) => {
	const gatsbyImageData =
		thumbnailItem.filename.childImageSharp.gatsbyImageData;

	const handleClick = () => {
		zoomIn(thumbnailItem.jsonId);
	};

	const containerClasses = ['gl-Thumbnails_ItemContainer'];
	if (isActive) {
		containerClasses.push('gl-Thumbnails_ItemContainer-isActive');
	}

	return (
		<div className={containerClasses.join(' ')} onClick={handleClick}>
			<div className={'gl-Thumbnails_Item'}>
				<GatsbyImage
					image={gatsbyImageData}
					alt={thumbnailItem.translation[lang].title}
					title={thumbnailItem.translation[lang].title}
				/>
			</div>
			<div className={'gl-Thumbnails_ItemTitle'}>
				{thumbnailItem.translation[lang].title}
			</div>
		</div>
	);
};

ThumbnailsItem.propTypes = {
	thumbnailItem: PropTypes.object,
	lang: PropTypes.string.isRequired,
	zoomIn: PropTypes.func.isRequired,
	isActive: PropTypes.bool.isRequired,
};

export default ThumbnailsItem;
