import './ThumbnailsNav.scss';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import config from '../../../config';
import { withTranslation } from 'react-i18next';

import { useSwipeable } from 'react-swipeable';
import Icon from '../../../ui/icon/Icon';

const keyboardShortcuts = {
	previous: ['k', 'K', 'ArrowLeft'],
	next: ['j', 'J', 'ArrowRight'],
	quit: ['g', 'G', 'Escape'],
	fullScreen: ['Enter'],
};

const PhotoNavigation = ({ zoomIn, zoomOut, activeThumb, allThumbnails }) => {
	const allThumbnailsId = allThumbnails.map((thumb) => thumb.jsonId);
	const activeIndex = allThumbnailsId.findIndex((el) => el === activeThumb);

	let hasPrevious = false;
	let previousId = '';

	if (activeIndex > 0) {
		hasPrevious = true;
		previousId = allThumbnailsId[activeIndex - 1];
	}

	let hasNext = true;
	let nextId = '';

	if (activeIndex >= allThumbnailsId.length - 1) {
		hasNext = false;
	} else {
		nextId = allThumbnailsId[activeIndex + 1];
	}

	const movePrevious = () => {
		if (hasPrevious) {
			zoomIn(previousId);
		}
	};

	const moveNext = () => {
		if (hasNext) {
			zoomIn(nextId);
		}
	};

	const quit = () => {
		if (activeThumb !== '') zoomOut('');
	};

	useEffect(() => {
		const handleKeyboard = (e) => {
			if (keyboardShortcuts.next.includes(e.key)) {
				moveNext();
			} else if (keyboardShortcuts.previous.includes(e.key)) {
				movePrevious();
			} else if (
				keyboardShortcuts.quit.includes(e.key) ||
				keyboardShortcuts.fullScreen.includes(e.key)
			) {
				quit();
			}
		};
		window.addEventListener('keyup', handleKeyboard);

		return () => {
			window.removeEventListener('keyup', handleKeyboard);
		};
	});

	const swipeHandlers = useSwipeable({
		onSwipedLeft: moveNext,
		onSwipedRight: movePrevious,
	});

	const left = hasPrevious ? (
		<div
			className={'glp-PhotoNavigation_ButtonPrevious'}
			onClick={movePrevious}
		>
			<Icon id={'circle-left'} />
		</div>
	) : (
		' '
	);

	const right = hasNext ? (
		<div className={'glp-PhotoNavigation_ButtonNext'} onClick={moveNext}>
			<Icon id={'circle-right'} />
		</div>
	) : (
		' '
	);

	return (
		<div {...swipeHandlers} className={'glp-PhotoNavigation_Container'}>
			<div className={'glp-PhotoNavigation_Previous'}>{left}</div>
			<div className={'glp-PhotoNavigation_FullScreen'}>
				<div
					className={'glp-PhotoNavigation_ButtonFullScreen'}
					onClick={quit}
				>
					<Icon id={'zoom-out'} />
				</div>
			</div>
			<div className={'glp-PhotoNavigation_Next'}>{right}</div>
		</div>
	);
};

PhotoNavigation.propTypes = {
	allThumbnails: PropTypes.array.isRequired,
	zoomIn: PropTypes.func.isRequired,
	zoomOut: PropTypes.func.isRequired,
	activeThumb: PropTypes.string,
	lang: PropTypes.string.isRequired,
};

PhotoNavigation.defaultProps = {
	photoPreviousSlug: '',
	photoNextSlug: '',
};

export default withTranslation(config.defaultI18nextNs)(PhotoNavigation);
