import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../../ui/layout/main/containers/Layout';
import HtmlHeaders from '../../ui/HtmlHeaders';

import Gallery from '../components/gallery/Gallery';

const GalleryPage = ({ data, pageContext, location }) => {
	const { gallery, galleryThumbnails } = data;
	const { allAlternateLang, lang } = pageContext;

	gallery.allPhotos = pageContext.allGalleryPhotoIds.map((jsonId) => {
		return galleryThumbnails.nodes.find((x) => x.jsonId === jsonId);
	});

	return (
		<Layout
			mainNavActive={gallery.jsonId}
			isFullScreen={false}
			allAlternateLang={allAlternateLang}
			lang={lang}
		>
			<HtmlHeaders
				title={gallery.translation[lang].title}
				metaDescription={gallery.translation[lang].metaDescription}
				path={location.pathname}
				allAlternateLang={allAlternateLang}
				lang={lang}
			/>
			<Gallery
				gallery={gallery}
				allGalleryThumbnails={gallery.allPhotos}
				lang={lang}
			/>
		</Layout>
	);
};

GalleryPage.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
	location: PropTypes.object.isRequired,
};

export default GalleryPage;

export const query = graphql`
	query (
		$galleryId: String
		$srcSetBreakpoints: [Int]
		$sizes: String
		$allGalleryPhotoIds: [String]
		$langEn: Boolean!
		$langFr: Boolean!
	) {
		gallery: galleryJson(jsonId: { eq: $galleryId }) {
			jsonId
			translation {
				en @include(if: $langEn) {
					slug
					title
					description
					metaDescription
				}
				fr @include(if: $langFr) {
					slug
					title
					description
					metaDescription
				}
			}
		}
		galleryThumbnails: allPhotoJson(
			filter: { jsonId: { in: $allGalleryPhotoIds } }
		) {
			nodes {
				jsonId
				filename {
					childImageSharp {
						gatsbyImageData(
							layout: FULL_WIDTH
							breakpoints: $srcSetBreakpoints
							sizes: $sizes
							placeholder: DOMINANT_COLOR
						)
					}
				}
				translation {
					en @include(if: $langEn) {
						slug
						title
					}
					fr @include(if: $langFr) {
						slug
						title
					}
				}
			}
		}
	}
`;
