import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ThumbnailsItem from './ThumbnailsItem';
import ThumbnailsNav from './ThumbnailsNav';

const Thumbnails = ({ allThumbnails, lang }) => {
	const [activeThumb, setActiveThumb] = useState('');

	const zoomIn = (id) => {
		window.scrollTo(0, 0);
		disableScroll();
		setActiveThumb(id);
	};

	const zoomOut = () => {
		enableScroll();
		setActiveThumb('');
	};

	return (
		<>
			<div className={'gl-Thumbnails_Container'}>
				{allThumbnails.map((thumbnail) => (
					<ThumbnailsItem
						key={thumbnail.jsonId}
						thumbnailItem={thumbnail}
						lang={lang}
						isActive={activeThumb === thumbnail.jsonId}
						zoomIn={zoomIn}
					/>
				))}
			</div>
			{activeThumb !== '' && (
				<ThumbnailsNav
					zoomIn={zoomIn}
					zoomOut={zoomOut}
					activeThumb={activeThumb}
					allThumbnails={allThumbnails}
					lang={lang}
				/>
			)}
		</>
	);
};

const disableScroll = () => {
	document.documentElement.style.overflow = 'hidden';
	document.body.scroll = 'no';
};

const enableScroll = () => {
	document.documentElement.style.overflow = 'scroll';
	document.body.scroll = 'yes';
};

Thumbnails.propTypes = {
	allThumbnails: PropTypes.array,
	gallerySlug: PropTypes.string,
	lang: PropTypes.string.isRequired,
};

export default React.memo(Thumbnails);
